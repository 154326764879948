import {Buttonmain} from "../componentes/buttonmain";
import { reqqResapi } from '../api/reqRes';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Users } from '../interface/usuario';
import { toast } from 'react-toastify';

export const Home = () => {

  const { register, handleSubmit } = useForm(); // Configura useForm
  const navigate = useNavigate();

  const onSubmit = (data:any) => {
    const Jsonsend = {
      action: "login",
      data: data
    }

    validarUsuario(Jsonsend);
  };

  const validarUsuario = async(Jsonsend:any) => {
    //llamado al api promesa y se le asigna la interfaz
       
        try {
          const resp = await reqqResapi.post<Users>('/users',Jsonsend).then(res => {
            let respuesta = res.data

            if (respuesta.error) {
              toast.error(respuesta.message, {
                position: 'top-right',
                autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
              });
            } else {
              localStorage.setItem('idusuario',respuesta.user.idusuario);
              localStorage.setItem('nombre',respuesta.user.nombre);
              localStorage.setItem('correo',respuesta.user.correo);
              localStorage.setItem('telefono',respuesta.user.telefono);
              localStorage.setItem('rol',respuesta.user.rol);
              localStorage.setItem('idempresa',respuesta.user.idempresa);

                if(res.data.user.rol==="1"){
                    // Muestra una notificación
                    toast.success("Bienvenido "+res.data.user.nombre, {
                      position: 'top-right',
                      autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
                    });
                    navigate('/home');
                }else if(res.data.user.rol==="2"){
                    // Muestra una notificación
                    toast.success("Bienvenido "+res.data.user.nombre, {
                      position: 'top-right',
                      autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
                    });
                    navigate('/empresa');
                }else{
                    alert("Rango de usuario no especificado");
                    // Muestra una notificación
                    toast.success("Rango de usuario no especificado", {
                      position: 'top-right',
                      autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
                    });
                    navigate('/login');
                }
            }
          }); 
    
        } catch (error) {
          console.error('Error:', error);
        }
  }
  
  return (
    <div className='container'>
        <div className='container-child logo-grande'>
            <img src={require("../src/logo-restaurates.png")} alt="" />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className='container-child menu-form'>
            <input {...register('correo')} type="email" className='logininput' placeholder='Usuario' required/>
            <input {...register('pass')} type="password" placeholder='Contraseña' required/>
     
            <p></p>
            <div className='btn-container'>
                
                <Buttonmain ruta={null} texto={"Iniciar"}/>
                
            </div>
        </form>
        
    </div>
  )
}
