import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Navigate, Routes, redirect, HashRouter } from 'react-router-dom';

import {Home} from "../interfaces/home";
import {Menu} from "../interfaces/menu";
import {MenuEmpresa} from "../interfaces/menuEmpresa";
import {Reportes} from "../interfaces/reportes";
import {ReportesDetalle} from "../interfaces/reportesDetalle";

export const AppRouter = () => {

  return (
    <HashRouter>
        <Routes>
            <Route path="/login" element={<Home/>}/>
            <Route path="/home" element={<Menu/>}/>
            <Route path="/egresos" element={<Reportes/>}/>
            <Route path="/egresos/historial" element={<ReportesDetalle/>}/>
            <Route path="/empresa" element={<MenuEmpresa/>}/>  
            <Route path="/*" element={<Navigate to="/login"/>}/>
        </Routes>
    </HashRouter>
  )
}
