import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useDetalles } from '../hooks/useDetalles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Table, TableBody, TableContainer, TableHead, TableRow,TableFooter } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { Theme, useTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { reqqResapi } from '../api/reqRes';
import { MetaMod } from '../interface/metaMod';
import CurrencyInput from 'react-currency-input-field';
import { Buttonmain } from '../componentes/buttonmain';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';

const ITEM_HEIGHT = 48;
const ITEM_WIDTH = "98%";
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "92%",
    },
  },
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "900"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 200,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const headerCellStyle = {
  position: 'sticky',
  top: 0,
  backgroundColor: 'white', // Puedes ajustar el color de fondo según tu diseño
  zIndex: 1, // Para asegurar que esté por encima del contenido desplazado
};

const addButtonCellStyle = {
  position: 'sticky',
  right: 0,
  bottom: -2,
  backgroundColor: 'white', // Puedes ajustar el color de fondo según tu diseño
  zIndex: 1, // Para asegurar que esté por encima del contenido desplazado
};

interface Subcategoria {
  idsubcategoria: string;
  subcategoria_nombre: string;
}

interface Categoria {
  idcategorias: string;
  categoria_nombre: string;
  Subcategorias: Subcategoria[];
}

export const ReportesDetalle = () => {

  const navigate = useNavigate();
  const { state } = useLocation();
  const [open, setOpen] = React.useState(false);
  const [openAdd, setOpenAdd] = React.useState(false);
  const [idcompra, setidcompra] = useState("");
  const [idsubcategoria, setidsubcategoria] = useState("");
  const [sucursal, setsucursal] = useState("");
  const [monto, setmonto] = useState("");
  const { register, handleSubmit, reset } = useForm(); // Configura useForm
  const [categoriainfo, setCategoriainfo] = useState<Categoria | null>(null);

  const {
    update,
    setUpdate,
    setfechaInicial,
    setfechaFinal,
    fechaFinal,
    fechaInicial,
    ComprasList,
    setIdcategoria,
    Idcategoria,
    sucursalid,
    setsucursalid
  } = useDetalles();


  useEffect(() => {

    // Cargar el valor de idusuario desde el localStorage al montar el componente
    const rol = localStorage.getItem('rol');

    if ( !rol ||rol === "2") {
      navigate("/login");
    }

    if(state){
      setCategoriainfo(state.categoria);
      setIdcategoria(state.categoria.idcategorias)
      setidsubcategoria(state.categoria.Subcategorias[0].idsubcategoria);
      setsucursal(state.sucursal);
      setsucursalid(state.sucursalid);

      if (state.fechainicial) {
        setfechaInicial(state.fechainicial);
      }

      if (state.fechafinal) {
        setfechaFinal(state.fechafinal);
      }

      console.log(state.sucursal);
    }else{
      navigate('/egresos');
    }

    if (localStorage.getItem("sucursalNombre")) {
      console.log(localStorage.getItem("sucursalNombre"));
      // setsucursal(localStorage.getItem("sucursalNombre"));
    }
  }, [])
  
   // Función para obtener la fecha actual con el primer día del mes en formato ISO (YYYY-MM-01)
   const obtenerFechaActualPrimerDia = () => {
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}-01`;
  };

  // Manejar cambios en el campo de fecha
  const handleFechaInicialChange = (event:any) => {
    const nuevaFechaInicial = event.target.value;
    if (nuevaFechaInicial <= fechaFinal) {
      setfechaInicial(nuevaFechaInicial);
    } else {
      // Si la nueva fecha inicial es mayor que la fecha final, no la actualices
      // Puedes mostrar un mensaje de error o realizar alguna otra acción aquí
    }
  };

  // Función para obtener la fecha actual en formato ISO (YYYY-MM-DD)
  const obtenerFechaActual = () => {
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Manejar cambios en el campo de fecha
  const handleFechaFinalChange = (event:any) => {
    const nuevaFechaFinal = event.target.value;
    if (nuevaFechaFinal >= fechaInicial) {
      setfechaFinal(nuevaFechaFinal);
    } else {
      // Si la nueva fecha final es menor que la fecha inicial, no la actualices
      // Puedes mostrar un mensaje de error o realizar alguna otra acción aquí
    }
  };

  function obtenerIniciales(nombreCompleto:string) {
    const palabras = nombreCompleto.split(' ');
    const iniciales = palabras.map(palabra => palabra[0].toUpperCase());
    return iniciales.join('');
  }

  function formatearFecha(fecha:string) {
    const partesFecha = fecha.split('-');
    if (partesFecha.length === 3) {
      const año = partesFecha[0].substring(2); // Tomar los últimos dos dígitos del año
      const mes = partesFecha[1];
      const dia = partesFecha[2];
      return `${dia}/${mes}/${año}`;
    }
    return fecha; // Devolver la fecha original si no se puede formatear
  }

  const handleOpen = (idcompra:string,monto:string) => {
    setidcompra(idcompra);
    setmonto(monto)
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = (data:any) => {

    if (localStorage.getItem('sucursalSeleccionada') && localStorage.getItem('idusuario')) {
      data.idusuario = localStorage.getItem('idusuario');
      data.idcompra = idcompra;
      data.precio = parseFloat(data.precio.replace(/,/g, ''));

      const Jsonsend = {
        action: "modifyCargo",  
        data: data
      }

      actualizarCompra(Jsonsend);

    } else {
      alert("Error: favor de seleccionar la sucursal o verificar si has iniciado sesion.");
    }
    
  };

  const actualizarCompra = async(Jsonsend:any) => {
    //llamado al api promesa y se le asigna la interfaz
       console.log(Jsonsend);
        try {
          const resp = await reqqResapi.post<MetaMod>('/cargos',Jsonsend).then(res => {
            let respuesta = res.data
            console.log(respuesta);

            if (respuesta.error) {
                toast.error("Error: favor de selaccionar un valor valido", {
                  position: 'top-right',
                  autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
                });
            } else {
                // Muestra una notificación
                toast.success(respuesta.message, {
                  position: 'top-right',
                  autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
                });
                setUpdate(update+1);
                handleClose();
            }
          }); 
    
        } catch (error) {
          console.error('Error:', error);
        }
  }

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    reset();
    setOpenAdd(false);
  };

  const handleChangeSubcat = (event: SelectChangeEvent) => {
    setidsubcategoria(event.target.value as string);
  };

  const onSubmitAdd = (data:any) => {

    if (sucursalid && localStorage.getItem('idusuario')) {
      data.idusuario = localStorage.getItem('idusuario');
      data.idsucursal = sucursalid;
      data.idcategoria = Idcategoria;
      data.idsubcategoria = idsubcategoria;
      data.precio = parseFloat(data.precio.replace(/,/g, ''));

      const Jsonsend = {
        action: "saveCargo",  
        data: data
      }

      addCompra(Jsonsend);

    } else {
      alert("Error: favor de seleccionar la sucursal o verificar si has iniciado sesion.");
    }
    
  };

  const addCompra = async(Jsonsend:any) => {
    //llamado al api promesa y se le asigna la interfaz
       console.log(Jsonsend);
        try {
          const resp = await reqqResapi.post<MetaMod>('/cargos',Jsonsend).then(res => {
            let respuesta = res.data
            console.log(respuesta);

            if (respuesta.error) {
              toast.error("Error: favor de selaccionar un valor valido", {
                position: 'top-right',
                autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
              });
            } else {
                // Muestra una notificación
                toast.success(respuesta.message, {
                  position: 'top-right',
                  autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
                });
                setUpdate(update+1);
                handleCloseAdd();
            }
          }); 
    
        } catch (error) {
          console.error('Error:', error);
        }
  }

  return (
    <div className='container'>
        <div className='container-child menu-mediano'>
            <div className='back' onClick={()=>navigate('/egresos', {state:{
                  fechainicial:fechaInicial,
                  fechafinal:fechaFinal
              }})}>
              <ArrowBackIosNewIcon></ArrowBackIosNewIcon>
            </div>
            <div className='encabezado'>Detalles egresos</div>
            <div className='logo-mediano'>
              <img src={require("../src/logo88.png")} onClick={()=>navigate('/home')} alt="" />
            </div>
            <div className='sucursal-titulo'>
              <h2>{sucursal}</h2>
              <p>{categoriainfo?.categoria_nombre}</p>
            </div>
            
            <div className='periodo-cont'>
              <label>Periodo</label>
              <input type="date" 
                value={fechaInicial || obtenerFechaActualPrimerDia()} // Valor por defecto
                onChange={handleFechaInicialChange}
              />
              <input 
                type="date"  
                value={fechaFinal || obtenerFechaActual()} // Valor por defecto
                onChange={handleFechaFinalChange}
              />
            </div>
        </div>
        <div className='container-child cat-list table'>
          <TableContainer component={Paper} sx={{ overflowX: 'auto', height:"75vh" }}>
            <Table sx={{ width:"100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell sx={{ ...headerCellStyle}}>Fecha</StyledTableCell>
                  <StyledTableCell sx={{ ...headerCellStyle}}>Concepto</StyledTableCell>
                  <StyledTableCell sx={{ ...headerCellStyle}} align="right">Monto</StyledTableCell>
                  <StyledTableCell sx={{ ...headerCellStyle}}>Quién</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  (ComprasList?.historial)
                  ?(<>
                    {
                      ComprasList?.historial.map((compra) => (
                        <StyledTableRow key={compra.idcompra}>
                          <StyledTableCell component="th" scope="row">
                            {formatearFecha(compra.fecha)}
                          </StyledTableCell>
                          <StyledTableCell >
                            {compra.nombre_subcategoria}
                          </StyledTableCell>
                          <StyledTableCell className='tdporcent' align="right">
                            <label className='metaMod' onClick={()=>handleOpen(compra.idcompra,compra.precio.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','))}>
                              ${compra.precio.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </label>
                          </StyledTableCell>
                          <StyledTableCell className='tdporcent' >
                            <label>
                              {obtenerIniciales(compra.nombre_usuario)}
                            </label>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    }
                  </>)
                  :(<></>)
                }
              </TableBody>
              <TableFooter>
                <TableRow>
                  <StyledTableCell sx={{ ...addButtonCellStyle}} component="th" scope="row">
                    <label onClick={()=>handleOpenAdd()} className="añadir">+ Añadir</label>
                  </StyledTableCell >
                  <StyledTableCell sx={{ ...addButtonCellStyle}} component="th" scope="row">
                  </StyledTableCell>
                  <StyledTableCell sx={{ ...addButtonCellStyle}} component="th" scope="row">
                  </StyledTableCell>
                  <StyledTableCell sx={{ ...addButtonCellStyle}} component="th" scope="row">
                  </StyledTableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          className='modal'
        >
          <Box sx={{ ...style, width: "80%", textAlign:"center", borderRadius:"20px"}}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: '25px',
                top: '8px',
              }}
            >
              <ClearIcon />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)}>
              <p>Modificar compra</p>
              <div className='modalinputcont'>
                <span className='destacar2'>$</span>
                <CurrencyInput
                  className='numberput'
                  id="input-example"
                  {...register('precio')}
                  decimalSeparator="."
                  groupSeparator=","
                  placeholder={monto}
                  required
                />
              </div>
              <Buttonmain ruta={null} texto={"Actualizar"}/>
            </form>
          </Box>
        </Modal>
        <Modal
          open={openAdd}
          onClose={handleCloseAdd}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          className='modal'
        >
          <Box sx={{ ...style, width: "80%", textAlign:"center", borderRadius:"20px"}}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseAdd}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: '25px',
                top: '8px',
              }}
            >
              <ClearIcon />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmitAdd)}>
              <p>Añadir compra</p>
              <Box sx={{ minWidth: 120 , borderColor: "orange", marginBottom: "40px"}}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Subcategoria</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={idsubcategoria}
                    label="Age"
                    onChange={handleChangeSubcat}
                  >
                   {categoriainfo?.Subcategorias?.map((Subcategoria) => (
                      <MenuItem key={Subcategoria.idsubcategoria} value={Subcategoria.idsubcategoria}>
                        {Subcategoria.subcategoria_nombre}
                      </MenuItem>
                    ))}
                    {/* Opción por defecto si no hay subcategorías */}
                    {(!categoriainfo?.Subcategorias || categoriainfo?.Subcategorias?.length === 0) && (
                      <MenuItem value={0}>Sin subcategorías</MenuItem>
                    )}
        
                  </Select>
                </FormControl>
              </Box>
              <div className='modalinputcont'>
                <span className='destacar2'>$</span>
                <CurrencyInput
                  className='numberput'
                  id="input-example"
                  {...register('precio')}
                  decimalSeparator="."
                  groupSeparator=","
                  placeholder='0.00'
                  required
                />
              </div>
              <Buttonmain ruta={null} texto={"Añadir"}/>
            </form>
          </Box>
        </Modal>
    </div>
  )
}
