import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useEmpresas } from '../hooks/useEmpresas';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Modal, OutlinedInput, Select } from '@mui/material';
import { Theme, useTheme } from '@mui/material/styles';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Buttonmain } from '../componentes/buttonmain';
import { reqqResapi } from '../api/reqRes';
import { CompraResp } from '../interface/compraResp';
import { MetaMod } from '../interface/metaMod';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ClearIcon from '@mui/icons-material/Clear';

const ITEM_HEIGHT = 48;
const ITEM_WIDTH = "98%";
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "92%",
    },
  },
};

function getStyles(name: string, personName: any, theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
    fontWeight: "900"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 200,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const Reportes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Cargar el valor de idusuario desde el localStorage al montar el componente
    const rol = localStorage.getItem('rol');

    if ( !rol ||rol === "2") {
      navigate("/login");
    }
  }, []);

  const {
    EmpresaInfo,
    update,
    setUpdate,
    setfechaInicial,
    setfechaFinal,
    fechaFinal,
    fechaInicial,
    sucursalid,
    setsucursalid,
    ComprasList,
    MetasList
  } = useEmpresas();

  const { state } = useLocation();
  const { register, handleSubmit, reset } = useForm(); // Configura useForm
  const [open, setOpen] = React.useState(false);
  const [openMeta, setOpenMeta] = React.useState(false);
  const theme = useTheme();

  // Estado para el valor del segundo campo de fecha
  const [idingreso, setidingreso] = useState('');
  const [ingreso, setIngreso] = useState("");
  const [fechaIngreso, setfechaIngreso] = useState('');
  const [idmeta, setidmeta] = useState('');
  const [meta, setmeta] = useState('');
  const [Egreso, setEgreso] = useState(0);
  const [Utilidad, setUtilidad] = useState(0);
  const [sucursal, setsucursal] = useState("");


   // Función para obtener la fecha actual con el primer día del mes en formato ISO (YYYY-MM-01)
   const obtenerFechaActualPrimerDia = () => {
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}-01`;
  };

  // Manejar cambios en el campo de fecha
  const handleFechaInicialChange = (event:any) => {
    const nuevaFechaInicial = event.target.value;
    if (nuevaFechaInicial <= fechaFinal) {
      setfechaInicial(nuevaFechaInicial);
    } else {
      // Si la nueva fecha inicial es mayor que la fecha final, no la actualices
      // Puedes mostrar un mensaje de error o realizar alguna otra acción aquí
    }
  };

  // Función para obtener la fecha actual en formato ISO (YYYY-MM-DD)
  const obtenerFechaActual = () => {
    const fechaActual = new Date();
    const year = fechaActual.getFullYear();
    const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
    const day = String(fechaActual.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Manejar cambios en el campo de fecha
  const handleFechaFinalChange = (event:any) => {
    const nuevaFechaFinal = event.target.value;
    if (nuevaFechaFinal >= fechaInicial) {
      setfechaFinal(nuevaFechaFinal);
    } else {
      // Si la nueva fecha final es menor que la fecha inicial, no la actualices
      // Puedes mostrar un mensaje de error o realizar alguna otra acción aquí
    }
  };

  useEffect(() => {
    const valorGuardado = localStorage.getItem('sucursalSeleccionada');

    if(state){
      if (state.fechainicial) {
        setfechaInicial(state.fechainicial);
      }
  
      if (state.fechafinal) {
        setfechaFinal(state.fechafinal);
      }
    }
    

    if (valorGuardado) {
      setsucursalid(valorGuardado);

      if (EmpresaInfo) {
        const selectedSucursal = EmpresaInfo?.sucursales.find((sucursal) => sucursal.idsucursal === valorGuardado);
        if (selectedSucursal) {
          setsucursal(selectedSucursal.sucursal_nombre);
          if (selectedSucursal.sucursal_nombre) {
            localStorage.setItem('sucursalNombre', selectedSucursal.sucursal_nombre);
            console.log(localStorage.getItem('sucursalNombre'));
          }
        }
      }
    }else{
      if (EmpresaInfo) {
        console.log(EmpresaInfo.sucursales[0].idsucursal);
        setsucursalid(EmpresaInfo.sucursales[0].idsucursal);
        localStorage.setItem('sucursalNombre',EmpresaInfo.sucursales[0].sucursal_nombre);
      }
    }
    console.log(sucursalid);
    if (EmpresaInfo) {
      console.log(EmpresaInfo);
      // localStorage.setItem('sucursalNombre',EmpresaInfo.sucursales[0].sucursal_nombre);

      EmpresaInfo.sucursales.forEach(sucursal => {
          if (sucursal.idsucursal === sucursalid) {

            if (sucursal.ingresos && sucursal.fecha_ingreso) {
              setidingreso(sucursal.id_ingreso);
              setIngreso(sucursal.ingresos); // Convertir a número
              setfechaIngreso(sucursal.fecha_ingreso);

              console.log(parseFloat(sucursal.ingresos));
              console.log(ingreso);

            }
          }
      });
    }

    if (ComprasList) {
      Calcular();
    }
  }, [EmpresaInfo,sucursalid,update,ComprasList])

  const handleChange = (event: any) => {
    const newValue: any = event.target.value;
    const selectedSucursal = EmpresaInfo?.sucursales.find((sucursal) => sucursal.idsucursal === newValue);

    setsucursalid(newValue); // Ahora puedes llamar a setid con el nuevo valor
    // Guardar el valor seleccionado en localStorage
    localStorage.setItem('sucursalSeleccionada', newValue);

    if (selectedSucursal) {
      console.log(selectedSucursal.sucursal_nombre);
      setsucursal(selectedSucursal.sucursal_nombre);
      if (selectedSucursal.sucursal_nombre) {
        localStorage.setItem('sucursalNombre', selectedSucursal.sucursal_nombre);
        console.log(localStorage.getItem('sucursalNombre'));
      }
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = (data:any) => {

    if (sucursalid && localStorage.getItem('idusuario')) {
      data.idusuario = localStorage.getItem('idusuario');
      data.idsucursal = sucursalid;

      const Jsonsend = {
        action: "setIngreso",
        data: data
      }

      actualizarIngreso(Jsonsend);
      reset();

    } else {
      alert("Error: favor de seleccionar la sucursal o verificar si has iniciado sesion.");
    }
    
  };

  const handleOpenMeta = (idcategoria:string,meta:string) => {
    setmeta(meta)

    if (MetasList?.categorias) {
      const metafiltrada = MetasList?.categorias.filter(meta => meta.idcategorias === idcategoria);
  
      if (metafiltrada.length>0) {
        setidmeta(metafiltrada[0].idmeta);
      }    
    }

    setOpenMeta(true);
  };

  const handleCloseMeta = () => {
    setOpenMeta(false);
  };

  const onSubmitMeta = (data:any) => {

    if (localStorage.getItem('sucursalSeleccionada') && localStorage.getItem('idusuario')) {
      data.idusuario = localStorage.getItem('idusuario');
      data.idmeta = idmeta;

      const Jsonsend = {
        action: "modifyMetas",
        data: data
      }

      actualizarMeta(Jsonsend);
      reset();

    } else {
      alert("Error: favor de seleccionar la sucursal o verificar si has iniciado sesion.");
    }
    
  };

  const actualizarMeta = async(Jsonsend:any) => {
    //llamado al api promesa y se le asigna la interfaz
       console.log(Jsonsend);
        try {
          const resp = await reqqResapi.post<MetaMod>('/empresa',Jsonsend).then(res => {
            let respuesta = res.data
            console.log(respuesta);

            if (respuesta.error) {
              toast.error("Error: favor de selaccionar un valor valido", {
                position: 'top-right',
                autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
              });
            } else {
                // Muestra una notificación
                toast.success(respuesta.message, {
                  position: 'top-right',
                  autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
                });
                setUpdate(update+1);
                handleCloseMeta();
            }
          }); 
    
        } catch (error) {
          console.error('Error:', error);
        }
  }

  const actualizarIngreso = async(Jsonsend:any) => {
    //llamado al api promesa y se le asigna la interfaz
       console.log(Jsonsend);
        try {
          const resp = await reqqResapi.post<CompraResp>('/empresa',Jsonsend).then(res => {
            let respuesta = res.data
            console.log(respuesta);

            if (respuesta.error) {
              toast.error("Error: favor de selaccionar un valor valido", {
                position: 'top-right',
                autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
              });
            } else {
                // Muestra una notificación
                toast.success(respuesta.message, {
                  position: 'top-right',
                  autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
                });
                setUpdate(update+1);
                handleClose();
            }
          }); 
    
        } catch (error) {
          console.error('Error:', error);
        }
  }

  const Calcular = () =>{
    if (ComprasList) {
      let Total = 0;
      ComprasList.cargos.forEach(cat => {
        Total = Total + parseFloat(cat.total.replace(/,/g, ''));

      });
      setEgreso(Total);
      setUtilidad(parseFloat(ingreso.replace(/,/g, '')) - Total);

    }
  }
  
  const GetTotalEgreso = (idcategoria:string) =>{
      let totalEgreso = 0;

      if (ComprasList) {
        const cargosFiltrados = ComprasList?.cargos.filter(cargo => cargo.idcategoria === idcategoria);

        if (cargosFiltrados) {
          totalEgreso = cargosFiltrados.reduce((total, cargo) => total + parseFloat(cargo.total.replace(/,/g, '')), 0);
        }
      }
      
      return totalEgreso;
  }

  const GetMeta = (idcategoria:string) =>{

    let meta = 0;

    if (MetasList?.categorias) {
      const metafiltrada = MetasList?.categorias.filter(meta => meta.idcategorias === idcategoria);
  
      if (metafiltrada.length>0) {
        meta = parseFloat(metafiltrada[0].meta);
      }    
    }
    return meta;
  }

  function calcularPorcentaje(totalEgreso:any, ingreso:any) {
    const resultado = (100 * totalEgreso) / parseFloat(ingreso.replace(/,/g, ''));
    let res = "0%";
    if (resultado) {
      res = resultado.toFixed(2) + '%'
    }
    return res;
  }

  function formatearFecha(fecha:string) {
    const partesFecha = fecha.split('-');
    if (partesFecha.length === 3) {
      const año = partesFecha[0].substring(2); // Tomar los últimos dos dígitos del año
      const mes = partesFecha[1];
      const dia = partesFecha[2];
      return `${dia}/${mes}/${año}`;
    }
    return fecha; // Devolver la fecha original si no se puede formatear
  }

  return (
    <div className='container'>
        <div className='container-child menu-mediano'>
            <div className='back' onClick={()=>navigate('/home')}>
              <ArrowBackIosNewIcon></ArrowBackIosNewIcon>
            </div>
            <div className='encabezado'>Egresos generales</div>
            <div className='logo-mediano'>
              <img src={require("../src/logo88.png")} onClick={()=>navigate('/home')} alt="" />
            </div>
            {
              (EmpresaInfo?.sucursales)
              ?(
                <div className='select'>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-name-label">Sucursal</InputLabel>
                    <Select
                      labelId="demo-simple-name-label"
                      id="demo-multiple-name"
                      value={sucursalid}
                      onChange={handleChange}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                    >
                      {EmpresaInfo.sucursales.map((sucursal:any) => (
                        <MenuItem
                          key={sucursal.idsucursal}
                          value={sucursal.idsucursal}
                          style={getStyles(sucursal, sucursalid, theme)}
                        >
                          {sucursal.sucursal_nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )
              :(<></>)
            }
            
            <div className='periodo-cont'>
              <label>Periodo</label>
              <input type="date" 
                value={fechaInicial || obtenerFechaActualPrimerDia()} // Valor por defecto
                onChange={handleFechaInicialChange}
              />
              <input 
                type="date"  
                value={fechaFinal || obtenerFechaActual()} // Valor por defecto
                onChange={handleFechaFinalChange}
              />
            </div>

            <form className='periodo-cont'>
              <table>
                <thead>
                  <tr>
                    <th>Ingreso</th>
                    <th>Egreso</th>
                    <th>Utilidad</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className='ingreso-table'>
                    <td>
                      {
                        (ingreso)
                        ?(<div className='ingreso-camp'><label onClick={()=>handleOpen()} className='ingreso'>${ingreso}</label></div>)
                        :(<></>)
                      }
                      <p>al {formatearFecha(fechaIngreso)}</p>
                    </td>
                    <td>${Egreso.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).replace('$', '')}
                    </td>
                    <td style={{ color: Utilidad > 0 ? 'green' : 'red' }}>${Utilidad.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).replace('$', '')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
            
        </div>
        <div className='container-child cat-list table'>
          <TableContainer component={Paper}>
            <Table sx={{ width:"100%" }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Categoría</StyledTableCell>
                  <StyledTableCell align="right">Total Egreso</StyledTableCell>
                  <StyledTableCell align="right">% Meta</StyledTableCell>
                  <StyledTableCell align="right">% Real</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  (EmpresaInfo?.categorias)
                  ?(<>
                    {
                      EmpresaInfo.categorias.map((categoria) => (
                        <StyledTableRow key={categoria.idcategorias}>
                          <StyledTableCell component="th" scope="row" onClick={()=>navigate('/egresos/historial', {state:{
                            categoria:categoria,
                            sucursal: localStorage.getItem('sucursalNombre'),
                            fechainicial:fechaInicial,
                            fechafinal:fechaFinal,
                            sucursalid:sucursalid
                          }})}>
                            <span className='sucnombre'>{categoria.categoria_nombre}</span>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            ${GetTotalEgreso(categoria.idcategorias).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </StyledTableCell>
                          <StyledTableCell className='tdporcent' align="right">
                            <label className='metaMod' onClick={()=>handleOpenMeta(categoria.idcategorias,GetMeta(categoria.idcategorias).toFixed(2))}>
                              {GetMeta(categoria.idcategorias).toFixed(2)}%
                            </label></StyledTableCell>
                          <StyledTableCell className='tdporcent' align="right">
                            <label style={{ color: parseFloat(calcularPorcentaje(GetTotalEgreso(categoria.idcategorias), ingreso)) <= parseFloat(GetMeta(categoria.idcategorias).toFixed(2)) ? 'green' : 'red' }}>
                              {calcularPorcentaje(GetTotalEgreso(categoria.idcategorias), ingreso)}
                            </label>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    }
                  </>)
                  :(<></>)
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          className='modal'
        >
          <Box sx={{ ...style, width: "80%", textAlign:"center", borderRadius:"20px"}}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: '25px',
                top: '8px',
              }}
            >
              <ClearIcon />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)}>
              <p>Ingreso mensual</p>
              <div className='modalinputcont'>
                <span className='destacar2'>$</span>
                <CurrencyInput
                  className='numberput'
                  id="input-example"
                  {...register('ingreso')}
                  decimalSeparator="."
                  groupSeparator=","
                  placeholder={ingreso}
                  required
                />
              </div>
              <Buttonmain ruta={null} texto={"Actualizar"}/>
            </form>
          </Box>
        </Modal>

        <Modal
          open={openMeta}
          onClose={handleCloseMeta}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          className='modal'
        >
          <Box sx={{ ...style, width: "80%", textAlign:"center", borderRadius:"20px"}}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseMeta}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: '25px',
                top: '8px',
              }}
            >
              <ClearIcon />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmitMeta)}>
              <p>Modificar meta</p>
              <div className='modalinputcont'>
                <CurrencyInput
                  className='numberput'
                  id="input-example"
                  {...register('meta')}
                  decimalSeparator="."
                  groupSeparator=","
                  placeholder={meta}
                  required
                />
                <span className='destacar2'>%</span>
              </div>
              <Buttonmain ruta={null} texto={"Actualizar"}/>
            </form>
          </Box>
        </Modal>
        
    </div>
  )
}
