import React from 'react'
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { orange } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";

interface Props {
    ruta: string | null;
    texto: string;
}

export const Buttonmain = ({ruta,texto}:Props) => {
    const navigate = useNavigate();

    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: "white",
        backgroundColor: "#ff6632",
        '&:hover': {
          backgroundColor: orange[700],
        },
        fontSize: "18px",
        borderRadius:50,
        margin: "auto"
      }));

  return (
    <>
    {
      (ruta)
      ?(<ColorButton variant="outlined" className='btn-main' onClick={()=>navigate(ruta)}>{texto}</ColorButton>)
      :(<ColorButton variant="outlined" className='btn-main' type="submit">{texto}</ColorButton>)
    }
    </>
    
    
  )
}
