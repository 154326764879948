import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import {Buttonmain} from "../componentes/buttonmain"

export const Menu = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Cargar el valor de idusuario desde el localStorage al montar el componente
    const rol = localStorage.getItem('rol');

    if ( !rol) {
      navigate("/login");
    }else if(rol === "2"){
      navigate("/empresa");
    }
  }, []);

  return (
    <div className='container'>
        <div className='container-child logo-grande'>
            <img src={require("../src/logo-restaurates.png")} alt="" />
        </div>
        <div className='container-child menu-buttons'>
                <Buttonmain ruta={"/empresa"} texto={"Comprar"}/>
                <Buttonmain ruta={"/egresos"} texto={"Ver Reportes"}/>
        </div>
    </div>
  )
}
