import React, { useEffect, useState } from 'react';
import { reqqResapi } from '../api/reqRes';
import { ComprasList } from '../interface/comprasList';
import { ComprasHistorial } from '../interface/comprasHistorial';

export const useDetalles = () => {
  
      // Función para obtener la fecha actual con el primer día del mes en formato ISO (YYYY-MM-01)
    const obtenerFechaActualPrimerDia = () => {
        const fechaActual = new Date();
        const year = fechaActual.getFullYear();
        const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}-01`;
    };

    // Función para obtener la fecha actual en formato ISO (YYYY-MM-DD)
    const obtenerFechaActual = () => {
        const fechaActual = new Date();
        const year = fechaActual.getFullYear();
        const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
        const day = String(fechaActual.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };  
    
    const [ComprasList, setComprasList] = useState<ComprasHistorial>();
    const [Idcategoria, setIdcategoria] = useState("");
    const [update, setUpdate] = useState(0);
    const [fechaInicial, setfechaInicial] = useState(obtenerFechaActualPrimerDia());
    const [fechaFinal, setfechaFinal] = useState(obtenerFechaActual());
    const [sucursalid, setsucursalid] = useState<string>('');

    useEffect(() => {
        getHisotial();
    }, [update,Idcategoria,fechaInicial,fechaFinal])
        
    const getHisotial = async() => {

        let jsonsend = {
            action: "getCargosHistorial",
                data:{
                    idsucursal:sucursalid,
                    idcategoria:Idcategoria,
                    finicial:fechaInicial,
                    ffinal:fechaFinal,

                }
        }

        console.log(jsonsend);

        if (Idcategoria && sucursalid) {
            const res = await reqqResapi.post<ComprasHistorial>('/cargos',jsonsend).then(res => {
                if(res.data.error){
                    console.log(res.data.message);
                    setComprasList(res.data);
                }else{
                    console.log(res.data);
                    setComprasList(res.data);
                }
    
            });  
        }
    }

  return{
    update,
    setUpdate,
    fechaInicial,
    setfechaInicial,
    fechaFinal,
    setfechaFinal,
    ComprasList,
    setIdcategoria,
    Idcategoria,
    sucursalid,
    setsucursalid
  } 
}