import React, { useEffect, useState } from 'react'
import {useEmpresas} from "../hooks/useEmpresas"
import { useForm } from "react-hook-form";
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import {Buttonmain} from "../componentes/buttonmain";
import { CompraResp } from '../interface/compraResp';
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { toast } from 'react-toastify';

// import aguaIcon from "https://compras.menu88.mx/media/bebidas/aguas.png";
// import cafeIcon from "../src/bebidas/cafe.png";
// import coctelesIcon from "../src/bebidas/cocteles.png";
// import digestivosIcon from "../src/bebidas/digestivos.png";
// import licoresIcon from "../src/bebidas/vinos.png";
// import resfrescos from "../src/bebidas/refrescos.png";
// import vinosIcon from "../src/bebidas/vinos.png";
// import abarrotesIcon from "../src/materia_prima/abarrotes.png";
// import carnesIcon from "../src/materia_prima/carnes.png";
// import lacteosIcon from "../src/materia_prima/lacteos.png";
// import mariscosIcon from "../src/materia_prima/mariscos.png";
// import otrosIcon from "../src/materia_prima/otros.png";
// import verdurasIcon from "../src/materia_prima/verduras.png";
// import mantenimientoIcon from "../src/mantto/mantto-limpieza.png";
// import marketingIcon from "../src/marketing/marketing.png";
// import rentaIcon from "../src/renta/renta.png";
// import aguaServiciosIcon from "../src/servicios/agua.png";
// import celularServiciosIcon from "../src/servicios/celular.png";
// import gasServiciosIcon from "../src/servicios/gas.png";
// import gasolinaServiciosIcon from "../src/servicios/gasolina.png";
// import luzServiciosIcon from "../src/servicios/luz.png";
// import sistemasServiciosIcon from "../src/servicios/sistemas.png";
// import telInternetServiciosIcon from "../src/servicios/tel-internet.png";
// import personal from "../src/personal/personal.png";
// import tarjeta from "../src/tarjeta/tarjeta.png";

import { textAlign } from '@mui/system';
import { reqqResapi } from '../api/reqRes';
import CurrencyInput from 'react-currency-input-field';
import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ConfetiExplosion from '../componentes/confetiExplosion';

type IconRoutes = {
  [nombre: string]: string;
};

const Iconsrutes: IconRoutes = {};
// Asigna las rutas a las claves correspondientes en Iconsrutes
Iconsrutes.Aguas = "https://compras.menu88.mx/media/bebidas/aguas.png";
Iconsrutes.Café = "https://compras.menu88.mx/media/bebidas/cafe.png";
Iconsrutes.Cocteles = "https://compras.menu88.mx/media/bebidas/cocteles.png";
Iconsrutes.Digestivos = "https://compras.menu88.mx/media/bebidas/digestivos.png";
Iconsrutes.Licores = "https://compras.menu88.mx/media/bebidas/licores.png";
Iconsrutes.Refrescos = "https://compras.menu88.mx/media/bebidas/refrescos.png";
Iconsrutes.Vinos = "https://compras.menu88.mx/media/bebidas/vinos.png";
Iconsrutes.Abarrotes = "https://compras.menu88.mx/media/materia_prima/abarrotes.png";
Iconsrutes.Carnes = "https://compras.menu88.mx/media/materia_prima/carnes.png";
Iconsrutes.Lacteos = "https://compras.menu88.mx/media/materia_prima/lacteos.png";
Iconsrutes.Mariscos = "https://compras.menu88.mx/media/materia_prima/mariscos.png";
Iconsrutes.Otros = "https://compras.menu88.mx/media/materia_prima/otros.png";
Iconsrutes.Verduras = "https://compras.menu88.mx/media/materia_prima/verduras.png";
Iconsrutes.Mantenimiento = "https://compras.menu88.mx/media/mantto/mantto-limpieza.png";
Iconsrutes.Marketing = "https://compras.menu88.mx/media/marketing/marketing.png";
Iconsrutes.Renta = "https://compras.menu88.mx/media/renta/renta.png";
Iconsrutes.Agua = "https://compras.menu88.mx/media/servicios/agua.png";
Iconsrutes.Celular = "https://compras.menu88.mx/media/servicios/celular.png";
Iconsrutes.Gas = "https://compras.menu88.mx/media/servicios/gas.png";
Iconsrutes.Gasolina = "https://compras.menu88.mx/media/servicios/gasolina.png";
Iconsrutes.Luz = "https://compras.menu88.mx/media/servicios/luz.png";
Iconsrutes.Sistemas = "https://compras.menu88.mx/media/servicios/sistemas.png";
Iconsrutes["Tel-Internet"] = "https://compras.menu88.mx/media/servicios/tel-internet.png";
Iconsrutes["Nómina"] = "https://compras.menu88.mx/media/personal/personal.png";
Iconsrutes.Alimentos = "https://compras.menu88.mx/media/personal/alimentos.png";
Iconsrutes.Tarjeta = "https://compras.menu88.mx/media/tarjeta/tarjeta.png";

const ITEM_HEIGHT = 48;
const ITEM_WIDTH = "98%";
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "92%",
    },
  },
};


function getStyles(name: string, personName: any, theme: Theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 200,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export const MenuEmpresa = () => {

  const navigate = useNavigate();
  const [comprador, setcomprador] = React.useState(false);

  useEffect(() => {
    // Cargar el valor de idusuario desde el localStorage al montar el componente
    const rol = localStorage.getItem('rol');

    if (!rol) {
      navigate("/compras/login");
    }else if(rol === "2"){
      setcomprador(true);
    }


  }, []);


  const { register, handleSubmit, reset } = useForm(); // Configura useForm
  const {EmpresaInfo} = useEmpresas();
  const [sucursalid, setsucursalid] = useState<string>('');
  const [idcategoria, setidcategoria] = useState<string>('');
  const [idsubcategoria, setidsubcategoria] = useState<string>('');
  const [subcatnom, setsubcatnom] = useState<string>('');
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [open, setOpen] = React.useState(false);
  const [showConfeti, setShowConfeti] = useState(false);

  const handleOpen = (idcat:string,idsubcat:string,subcatnom:string) => {
    setidcategoria(idcat);
    setidsubcategoria(idsubcat);
    setsubcatnom(subcatnom);
    setOpen(true);
  };
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const theme = useTheme();

  useEffect(() => {
    const valorGuardado = localStorage.getItem('sucursalSeleccionada');
    if (valorGuardado) {
      setsucursalid(valorGuardado);
    }else{
      if (EmpresaInfo) {
        setsucursalid(EmpresaInfo.sucursales[0].idsucursal);
      }
    }

    console.log(sucursalid);
  }, [sucursalid]);


  const handleChange = (event: any) => {
    const newValue: any = event.target.value;
    setsucursalid(newValue); // Ahora puedes llamar a setid con el nuevo valor
    // Guardar el valor seleccionado en localStorage
    localStorage.setItem('sucursalSeleccionada', newValue);
  };

  const abrirDropbox = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
  };

  const searchIcon = (iconname: string) => {
    // Verifica si el icono existe en Iconsrutes
    if (Iconsrutes.hasOwnProperty(iconname)) {
      return Iconsrutes[iconname];
    }
  
    // Si no se encuentra el icono, devuelve una ruta predeterminada
    return Iconsrutes["Otros"];
  };
  
  const onSubmit = (data:any) => {

    if (localStorage.getItem('sucursalSeleccionada') && localStorage.getItem('idusuario')) {
      data.idusuario = localStorage.getItem('idusuario');
      data.idsucursal = localStorage.getItem('sucursalSeleccionada');
      data.idcategoria = idcategoria;
      data.idsubcategoria = idsubcategoria;
      data.precio = parseFloat(data.precio.replace(/,/g, ''))

      const Jsonsend = {
        action: "saveCargo",
        data: data
      }

      registrarCompra(Jsonsend);
    } else {
      alert("Error: favor de seleccionar la sucursal o verificar si has iniciado sesion.");
    }
    
  };

  const registrarCompra = async(Jsonsend:any) => {
    //llamado al api promesa y se le asigna la interfaz
       console.log(Jsonsend);
        try {
          const resp = await reqqResapi.post<CompraResp>('/cargos',Jsonsend).then(res => {
            let respuesta = res.data
            console.log(respuesta);

            if (respuesta.error) {
              // Muestra una notificación
              toast.error(respuesta.message, {
                position: 'top-right',
                autoClose: 1500, // La notificación se cerrará automáticamente después de 3 segundos
              });
            } else {
                setidcategoria("");
                setidsubcategoria("");
                handleClose();
                // Muestra una notificación
                toast.success(respuesta.message, {
                  position: 'top-right',
                  autoClose: 1500
                });
                setShowConfeti(true);
                setTimeout(() => {
                  setShowConfeti(false);
                }, 5000);
            }
          }); 
    
        } catch (error) {
          console.error('Error:', error);
        }
  }

  return (
    <div className='container'>
        <div className='container-child menu-mediano'>
            <div className='back'>
              {
                (comprador)
                ?(
                  <></>
                )
                :(
                  <ArrowBackIosNewIcon onClick={()=>navigate('/home')}></ArrowBackIosNewIcon>
                )
              }
            </div>
            <div className='encabezado'>Comprando para</div>
            <div className='logo-mediano'>
              <img src={require("../src/logo88.png")} alt="" />
            </div>
            {
              (EmpresaInfo?.sucursales)
              ?(
                <div className='select'>
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel id="demo-simple-name-label">Sucursal</InputLabel>
                    <Select
                      labelId="demo-simple-name-label"
                      id="demo-multiple-name"
                      value={sucursalid}
                      onChange={handleChange}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={MenuProps}
                    >
                      {EmpresaInfo.sucursales.map((sucursal:any) => (
                        <MenuItem
                          key={sucursal.idsucursal}
                          value={sucursal.idsucursal}
                          style={getStyles(sucursal, sucursalid, theme)}
                        >
                          {sucursal.sucursal_nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )
              :(<></>)
            }
            
        </div>
        {showConfeti && (
          <div style={{
            display: 'flex',
            justifyContent: 'center', // Centrar horizontalmente
            alignItems: 'center',    // Centrar verticalmente
            height: '100vh',         // Altura del 100% de la ventana
            position: 'fixed',       // Posición fija para cubrir toda la ventana
            top: 0,
            left: 0,
            width: '100%',
            backgroundColor: "transparent" // Fondo semitransparente
          }}>
            <ConfetiExplosion />
          </div>)
        } {/* Renderiza el ConfetiExplosion si showConfeti es verdadero */}
        <div className='container-child cat-list'>
          {
            EmpresaInfo?.categorias ? (
              <div>
                {EmpresaInfo.categorias.map((categoria) => (
                  <Accordion
                    expanded={expanded === categoria.idcategorias}
                    onChange={abrirDropbox(categoria.idcategorias)}
                    key={categoria.idcategorias}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel1bh-content-${categoria.idcategorias}`}
                      id={`acord-${categoria.idcategorias}`}
                    >
                      <Typography sx={{ width: '50%%', flexShrink: 0 }}>
                          {categoria.categoria_nombre}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className='list-cont'>
                      <ul className='icon-list'>
                        {categoria.Subcategorias
                          ? categoria.Subcategorias.map((subcategoria) => (
                              <li key={subcategoria.idsubcategoria} onClick={()=>handleOpen(
                                categoria.idcategorias,
                                subcategoria.idsubcategoria,
                                subcategoria.subcategoria_nombre
                                )}>
                                <img src={searchIcon(subcategoria.subcategoria_nombre)} alt="" />
                                <p>{subcategoria.subcategoria_nombre}</p>
                              </li>
                            ))
                          : <></>
                        }
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            ) : (
              <></>
            )
          }
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          className='modal'
        >
          <Box sx={{ ...style, width: "80%", textAlign:"center", borderRadius:"20px"}}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: '25px',
                top: '8px',
              }}
            >
              <ClearIcon />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)}>
              <h1 id="parent-modal-title">Cargo: <span className='destacar'>{subcatnom}</span></h1>
              <p>Monto</p>
              <div className='modalinputcont'>
                <span className='destacar2'>$</span>
                <CurrencyInput
                  className='numberput'
                  id="input-example"
                  {...register('precio')}
                  decimalSeparator="."
                  groupSeparator=","
                  placeholder='0.00'
                  required
                />
              </div>
              <Buttonmain ruta={null} texto={"Registrar"}/>
            </form>
          </Box>
        </Modal>
    </div>
  )
}
