import React, { useEffect, useState } from 'react';
import { reqqResapi } from '../api/reqRes';
import { Empresas } from '../interface/empresas';
import { ComprasList } from '../interface/comprasList';
import { MetasList } from '../interface/metaList';

export const useEmpresas = () => {
  
      // Función para obtener la fecha actual con el primer día del mes en formato ISO (YYYY-MM-01)
    const obtenerFechaActualPrimerDia = () => {
        const fechaActual = new Date();
        const year = fechaActual.getFullYear();
        const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}-01`;
    };

    // Función para obtener la fecha actual en formato ISO (YYYY-MM-DD)
    const obtenerFechaActual = () => {
        const fechaActual = new Date();
        const year = fechaActual.getFullYear();
        const month = String(fechaActual.getMonth() + 1).padStart(2, '0');
        const day = String(fechaActual.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };  
    
    const [EmpresaInfo, setEmpresaInfo] = useState<Empresas>();
    const [ComprasList, setComprasList] = useState<ComprasList>();
    const [MetasList, setMetaList] = useState<MetasList>();
    const [Empresaid, setEmpresaid] = useState<string>();
    const [update, setUpdate] = useState(0);
    const [fechaInicial, setfechaInicial] = useState(obtenerFechaActualPrimerDia());
    const [fechaFinal, setfechaFinal] = useState(obtenerFechaActual());
    const [sucursalid, setsucursalid] = useState<string>('');

    useEffect(() => {
        getEmpresa();
    }, [update])

    useEffect(() => {
        getCompras();
        getMetas();
    }, [fechaInicial,fechaFinal,update,sucursalid,Empresaid])
    
    const getEmpresa = async() => {
        let jsonsend = {
            action: "getEmpresa",
                data:{
                    idempresa:localStorage.getItem('idempresa')
                }
        }

        const res = await reqqResapi.post<Empresas>('/empresa',jsonsend).then(res => {
            if(res.data.error){
                alert(res.data.message);

            }else{
                setEmpresaInfo(res.data);
                setEmpresaid(res.data.sucursales[0].idempresa);

                // getMetas(res.data.sucursales[0].idempresa);
            }

        });   
    }

    const getCompras = async() => {
        let jsonsend = {
            action: "getCargos",
                data:{
                    idsucursal:sucursalid,
                    finicial:fechaInicial,
                    ffinal:fechaFinal,
                }
        }
        console.log(jsonsend);
        if (sucursalid) {
            const res = await reqqResapi.post<ComprasList>('/cargos',jsonsend).then(res => {
                if(res.data.error){
                    console.log(res.data.message);
                    setComprasList(undefined);
                }else{
                    console.log(res.data);
                    setComprasList(res.data);
                }
            });   
        }
    }

    const getMetas = async() => {
        let jsonsend = {
            action: "getMetas",
                data:{
                    idempresa:Empresaid,
                    idsucursal:sucursalid,
                }
        }
        console.log(jsonsend);
        if (Empresaid && sucursalid) {
            const res = await reqqResapi.post<MetasList>('/empresa',jsonsend).then(res => {
                if(res.data.error){
                    console.log(res.data.message);
                }else{
                    console.log(res.data);
                    setMetaList(res.data)
                }
            });   
        }
    }   

  return{
    EmpresaInfo,
    update,
    setUpdate,
    fechaInicial,
    setfechaInicial,
    fechaFinal,
    setfechaFinal,
    sucursalid,
    setsucursalid,
    ComprasList,
    MetasList
  } 
}