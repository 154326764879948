import React from 'react';
import { AppRouter } from "./router/AppRouter";
import './css/main.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div>
      <AppRouter/>
      <ToastContainer />
    </div>
  );
}

export default App;
