import React from 'react';
import ConfettiExplosion from 'react-confetti-explosion';

function ConfetiExplosion() {
  return (
    <div>
      <ConfettiExplosion 
        force={0.6}           // Personaliza la fuerza del confeti
        duration={2500}       // Personaliza la duración de la animación
        particleCount={100}    // Personaliza la cantidad de partículas
        width={1000}    
        />
    </div>
  );
}

export default ConfetiExplosion;
